import { useForm, ValidationResult } from "vee-validate";
import * as Yup from "yup";
import { Ref } from "vue";
import dayjs from "dayjs";

const schema = Yup.object({
  email: Yup.string()
    .required("This field is required")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Email is not valid."),

  password: Yup.string().required("This field is required."),

  createPassword: Yup.string()
    .required("This field is required.")
    .min(8, "Password is too short.")
    .matches(
      // eslint-disable-next-line unicorn/better-regex
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_+\-=])[\w!@#$%^&*_+\-=]{8,}$/,
      "Password does not meet minimum security requirements.",
    ),

  newPasswordConfirmation: Yup.string()
    .required("This field is required.")
    .oneOf([Yup.ref("createPassword")], "Passwords do not match."),

  firstName: Yup.string().required("This field is required.").max(255, "First name is too long. Max 255 characters."),
  lastName: Yup.string().required("This field is required.").max(255, "Last name is too long. Max 255 characters."),

  mobile: Yup.string()
    .required("This field is required.")
    // eslint-disable-next-line unicorn/better-regex
    .max(11, "Mobile number must not exceed 11 characters.")
    .matches(
      /^(?:\+44\s?7\d{3}|\(?07\d{3}\)?|\+44\s?1\d{3}|\+44\s?2\d{3}|\(?01\d{3}\)?|\(?02\d{3}\)?)\s?\d{3,4}\s?\d{3,4}$/,
      "Mobile number is not valid.",
    ),
  postcode: Yup.string()
    .required()
    .matches(/^(gir 0aa|[a-z]{1,2}\d[\da-z]?\s?\d[a-z]{2})$/i, "Postcode is not valid."),

  // this matches the backend validation
  landline: Yup.string().matches(/^0[\d ]{9,10}$|^$/, "Landline number is not valid."),

  faxNumber: Yup.string()
    // eslint-disable-next-line unicorn/better-regex
    .max(11, "Fax number must not exceed 11 characters.")
    .matches(
      // eslint-disable-next-line vue/max-len
      /^(?:\+44\s?7\d{3}|\(?07\d{3}\)?|\+44\s?1\d{3}|\+44\s?2\d{3}|\(?01\d{3}\)?|\(?02\d{3}\)?)\s?\d{3,4}\s?\d{3,4}$|^$/,
      "Fax number is not valid.",
    ),

  checked: Yup.boolean().required("This field is required."),

  firstAddress: Yup.string().required("This field is required."),
  secondAddress: Yup.string(),
  thirdAddress: Yup.string(),

  county: Yup.string(),
  city: Yup.string().required("This field is required."),

  preferredBranch: Yup.string().required("This field is required."),
  profession: Yup.string().required("This field is required."),

  registrationNumber: Yup.string().required("This field is required."),

  businessName: Yup.string().required("This field is required."),
  businessType: Yup.string().required("This field is required."),

  creditLimit: Yup.number()
    .required("This field is required.")
    .max(999_999, "Maximum value is £999999.")
    .min(1, "Minimum value is £1.")
    .test("is-full-integer", "This field must be a full number (no decimal points allowed).", (value, context) => {
      // Ensure the original input does not contain a decimal point
      return context.originalValue && !context.originalValue.toString().includes(".");
    })
    .typeError("This field must be a number."),

  filterInvoicesRange: Yup.string().matches(
    // eslint-disable-next-line vue/max-len, unicorn/better-regex
    /^\s*$|\b(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/[0-9]{4} - (0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/[0-9]{4}\b/,
    "Date is not valid. Valid format: DD/MM/YYYY - DD/MM/YYYY",
  ),

  filterOrdersRAnge: Yup.string().matches(
    // eslint-disable-next-line vue/max-len, unicorn/better-regex
    /^\s*$|\b(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/[0-9]{4} - (0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/[0-9]{4}\b/,
    "Date is not valid. Valid format: DD/MM/YYYY - DD/MM/YYYY",
  ),

  creditAccountCreateDob: Yup.string()
    .matches(
      // eslint-disable-next-line unicorn/better-regex
      /^\s*$|\b(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/[0-9]{4}\b/,
      "Date is not valid. Valid format: DD/MM/YYYY",
    )
    .required("This field is required.")
    .test("is-18-or-older", "You must be at least 18 years old.", function (value) {
      const dob = dayjs(value, "DD/MM/YYYY");
      return dayjs().diff(dob, "year") >= 18;
    }),

  businessFormedDate: Yup.string()
    .matches(
      // eslint-disable-next-line unicorn/better-regex
      /^\s*$|\b(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/[0-9]{4}\b/,
      "Date is not valid. Valid format: DD/MM/YYYY",
    )
    .required("This field is required."),

  businessMovedDate: Yup.string()
    .matches(
      // eslint-disable-next-line unicorn/better-regex
      /^\s*$|\b(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/[0-9]{4}\b/,
      "Date is not valid. Valid format: DD/MM/YYYY",
    )
    .required("This field is required."),

  brochureDeliveryMethod: Yup.string()
    .required("Please select a delivery method.")
    .oneOf(["email", "post"], "Please pick a delivery method."),
});

// eslint-disable-next-line unicorn/expiring-todo-comments
// TODO: Refactor this to use state instead of ref
const errors: Ref<{ [key: string]: string }> = ref({});

export function useVeeValidate() {
  const { validateField, setFieldValue } = useForm({ validationSchema: schema });

  const fieldHasError = (fieldName: string) => computed(() => (errors?.value[fieldName]?.length ?? 0) > 0);
  const fieldError = (fieldName: string) => computed(() => errors?.value[fieldName]);

  const validateSingleField = async (event: FocusEvent | Event): Promise<void> => {
    const target = event.target as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

    if (
      target &&
      "validationRule" in target.dataset &&
      typeof target.dataset.validationRule === "string" &&
      "value" in target
    ) {
      setFieldValue(target.dataset.validationRule, target.value);

      const validationResult: ValidationResult = await validateField(target.dataset.validationRule || "");

      if (!validationResult.valid) {
        // this is done to ensure reactivity
        errors.value = { ...errors.value, [target.dataset.validationRule]: validationResult.errors[0] };
        return;
      }

      delete errors.value[target.dataset.validationRule];
    }
  };

  const validateAllFields = async (fields: { [key: string]: string | boolean | null }) => {
    for (const fieldName in fields) {
      setFieldValue(fieldName, fields[fieldName]);

      const validationResult: ValidationResult = await validateField(fieldName);

      if (validationResult.valid) {
        delete errors.value[fieldName];
      } else {
        errors.value = { ...errors.value, [fieldName]: validationResult.errors[0] };
      }
    }
  };

  const clearErrors = (fields?: Array<string>) => {
    fields?.forEach((field) => delete errors.value[field]);

    if (!fields || fields.length === 0) {
      errors.value = {};
    }
  };

  return {
    validateSingleField,
    validateAllFields,
    fieldHasError,
    clearErrors,
    fieldError,
    errors,
  };
}
